body {
    background-image: url("./../../public/backImg.jpg");
    background-size: 100vw 200vh;
    background-repeat: no-repeat;
}
.brand:active, /* активная/посещенная ссылка */
.brand:hover,  /* при наведении */
.brand {
  text-decoration: none;
  color: white;
}
.pagination{
    margin-bottom: 3px;
}
.navbar-text{
    font-size: 20px;
}

@media (max-width: 770px) {
    body{
        background: linear-gradient(#cc99ff, #66ff33);
    }
    .pagination{
        --bs-pagination-padding-x: 0.4rem;
        --bs-pagination-padding-y: 0.3rem;
    }
    .type-bar{
        display: block;
        font-size: 0.7rem;
    }
    .list-group-item{
        display: inline-block;
        margin: 2px;
        border: 1px solid crimson;
    }
    .list-group-item:last-child {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .list-group-item+.list-group-item {
        border-top-width: thin;
    }
    .navbar-brand{
        font-size: 0.9rem;
    }
    .navbar-text{
        font-size: 0.7rem;
    }
    .footer{
        font-size: 0.9rem;
    }
    .my-modal-image{
        margin: auto;
    }
    .my-modal-info{
        margin: auto;
        text-align: center;
        padding: 10px;
    }
  }
  