/* .Arrow {
    width: 20px;
    height: 20px;
    border-top: 2px solid rgb(4, 83, 202);
    border-right:2px solid rgb(4, 83, 202);
    transform: rotate(45deg);
    margin-top: 3px;
    margin-left: 130px;
    float: right;
} */
.ListHeader {
    display: flex;
    color: black;
    border-radius: 12px 12px 0 0;
    height: 2.5rem;
    padding: 7px;
    background-color: #04d1bb;
    user-select: none;
}
/* .ActiveArrow {
    width: 20px;
    height: 20px;
    border-top: 2px solid rgb(4, 83, 202);
    border-right:2px solid rgb(4, 83, 202);
    margin-top: 1px;
    margin-left: 130px;
    transform: rotate(135deg);
} */
