.card-item {
    width: 12vw;
     user-select: none;
    text-align: center;
     border: 1px solid green;
     margin-top: 1rem;

}
.card-text{
  font-size: 1rem;
}
.card-img-top{
  width: 100%;
  height: 20vh;
}
.product-name {
  height: 10vh;
  font-size:1rem;
  overflow: hidden;
}
.modal-item{
  width: 90%;
  height: 20vh;
}
.card-button {
border: none;
  padding: 12px 24px;
  border-radius: 24px;
  font-size: 1.5vh;
  letter-spacing: 1px;
  background: rgba(3, 170, 100, 0.9);
  color: rgba(255,255,255,0.95);
  filter: drop-shadow(0);
  font-weight: bold;
  transition: all .3s ease;   
}
.card-button:hover{
    transform: scale(1.125);  
    border-color: rgba(255, 255, 255, 0.9);
    filter: drop-shadow(0 10px 5px rgba(0,0,0,0.125));
    transition: all .3s ease;    
  }
  .modal-price{
    font-size: 1.6rem;
  }
  
  @media  (max-width: 450px) { 
    .row>*{
      width: auto;
      padding-right: 0px;
     }
     .card-item{
      width: 20vw;
     padding-left: 1px;
     }
     .card-body{
      padding: 2px;
     }
     .card-text {
      font-size: 0.55rem;
    }
    .card-button {
      padding: 3px 6px;
      font-size: 0.4rem;
      letter-spacing: 0.2px;
    }
    .card-img-top{
      height: 13vh;
    }
    .product-name {
     height: 5vh;
     font-size:0.5rem;
   }
   .modal-dialog{
    width: 200px;
    height: 50px;
    font-size: 0.4rem;
    margin: auto;
  }
  .modal-header{
    font-size: 1rem;
  }
  .modal-body  h4 {
    font-size: 0.9rem;
  }
  .modal-text{
    font-size: 0.6rem;
  }
  .modal-price{
    font-size: 0.6rem;
  }
  .modal-body{
    padding: 10px;
  }
  }
  @media  (max-width: 300px){
    .card-button{
      letter-spacing: 0px;
      font-size: 0.3rem;
    }
  }
  @media (min-width: 450px) and (max-width: 770px) { 
    .row>*{
      width: auto;
     }
     .card-item{
      width: 17vw;
     padding-left: 2px;
     }
     .card-body{
      padding: 5px;
     }
     .card-text {
      font-size: 0.5rem;
    }
    .card-button {
      padding: 4px 8px;
      font-size: 0.5rem;
      letter-spacing: 0.2px;
    }
    .card-img-top{
      height: 13vh;
    }
    .product-name {
     height: 7vh;
     font-size:0.6rem;
   }
    .separating-line{
      margin: 5px 0px;
    }
    .modal-dialog{
      width: 350px;
      height: 100px;
      font-size: 2vh;
      margin: auto;
    }
    .modal-header{
      font-size: 3vh;
    }
    .modal-body  h4 {
      font-size: 2vh;
    }
    .modal-price{
      font-size: 2vh;
    }
    .modal-body{
      padding: 10px;
    }
  }
  @media (min-width: 771px) { 
    .card-item{
      width: 100%;
     }
     .card-button{
      padding: 5px 10px;
      font-size: 0.6rem;
      letter-spacing: 0.5px;
     }
     .card-text{
      font-size: 0.7rem;
    }
     .modal-item{
      width: 150px;
      height: 100px;
    } 
    .card-img-top{
      height: 15vh;
    }
    .product-name {
      height: 10vh;
      font-size:0.7rem;
    }
  }
  @media (min-width: 992px) {  
    .card-item{
      width: 100%;
     } 
     .modal-item{
      width: 200px;
      height: 150px;
    } 
    .product-name {
      height: 10vh;
      font-size:0.8rem;
    }
   }
  @media (min-width: 1200px)  { 
     .card-item{
      width: 100%;
     }
     .card-button{
      padding: 12px 24px;
      font-size: 0.9rem;
      letter-spacing: 1px;
     }
     .card-img-top{
      height: 20vh;
    }
     
    }
  @media (min-width: 1300px)  { 
     .card-item{
      width: 100%;
     } 
     .card-img-top{
      height: 20vh;
    }
    .product-name {
      height: 10vh;
      font-size:0.9rem;
    }
    .card-text{
      font-size: 0.9rem;
    }
    }
    @media (min-width: 1800px) {
      .card-item{
      width: 100%;
      }
      .card-img-top{
        height: 20vh;
      }
    }