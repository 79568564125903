#dropdown-item-button {
    background-color: white;
    color: black;
    border: 1px solid crimson;
}
.dropdownProductsType{
    display: inline;
    font-size: 1.5rem;
    color: black;
    font-weight: bold;
    text-transform: uppercase
}
.dropdown{
    float: right;
}
.form{
    display: inline-block;
}
.search_input{
    border-radius: 7px;
}
.search_img{
    width: 30px;
    height: 30px;
}
.autocomplete{
    /* position: absolute; */
    left: 0;
    top: 50px;
    width: 100%;
    background: white;
    list-style: none;
    margin: 0;
    padding: 0;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.15);
    z-index: 11;
    max-height: 240px;
    height: auto;
    overflow: auto;
}
.autocomplete_item{
    padding: 10px;
}
.autocomplete_item:hover {
    background: rgb(180,180,180);
    cursor: pointer;
}
@media (max-width: 950px){
    .dropdownProductsType{
        font-size: 1.2rem;

    }
}
@media (max-width: 450px) {
    .dropdownProductsType{
        font-size: 0.6rem;
        line-height: 40px;

    }
    .dropdown-sort{
        padding: 6px;

    }
    .search_img{
        width: 15px;
        height: 15px;
    }
}
@media (max-width: 330px){
    .dropdownProductsType{
        font-size: 0.5rem;

    }
    .btn {
        --bs-btn-font-size: 0.65rem;
    }
    .dropdown-menu {
        --bs-dropdown-font-size: 0.65rem
    }
}
@media (max-width: 770px) {
    .dropdown-sort{
       width: 95vw;

    }
}
